@import "./branding.scss";

// BUTTONS

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  &:not(.mat-button-disabled):not([disabled]) {
    @include whitelabelGlobal(primary) using ($primary) {
      background-color: $primary;
    }
  }
}

.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
  &:not(.mat-button-disabled):not([disabled]) {
    @include whitelabelGlobal(accent) using ($accent) {
      background-color: $accent;
    }
  }
}

.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
  &:not(.mat-button-disabled) {
    @include whitelabelGlobal(primary) using ($primary) {
      color: $primary;
    }
  }
}

.mat-button.mat-accent,
.mat-icon-button.mat-accent,
.mat-stroked-button.mat-accent {
  &:not(.mat-button-disabled) {
    @include whitelabelGlobal(accent) using ($accent) {
      color: $accent;
    }
  }
}

// TABS

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #04D464;
}

mat-tab-header .mat-tab-label.mat-tab-label-active {
  @include whitelabelGlobal(primary) using ($primary) {
    color: $primary;
  }
}

.mat-progress-bar-fill::after {
  background-color: $accentColor;
}

.mat-progress-bar-buffer {
  background-color: #C7DDFF;
}

