@import "./theme";
@import "./material-custom";
@import "./material-no-anim";
@import "./material-branding.scss";
@import "./datepicker";
@import "./bugfix";
@import "./variables";
@import "./skeleton";
@import "./branding.scss";
@import "./typography";
@import "vertical-tabs";

html,
body {
  height: 100%;
  background-color: #edeef2;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  font-family: inherit;
}

input.cdk-text-field-autofilled {
  @include cdk-text-field-autofill-color(transparent, "");
}

// Custom Classes

[hidden] {
  display: none !important;
}

.none {
  display: none;
}

.hidden {
  visibility: hidden;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.center {
  text-align: center;
  justify-content: center;
}

.right {
  text-align: right;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;

  &.mat-button,
  &.mat-button-base {
    .mat-button-wrapper {
      display: inline-flex !important;
      text-overflow: ellipsis;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      width: 100%;

      > span {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  &.mat-checkbox {
    label.mat-checkbox-layout {
      overflow: hidden;
      max-width: 100%;
    }
  }
}

// SCROLLBAR

::-webkit-scrollbar {
  width: $scrollBarSize;
  height: $scrollBarSize;
  background: #DDDBD9;
  -webkit-appearance: none;
  -webkit-overflow-scrolling: auto;
}

::-webkit-scrollbar-thumb {
  background: #CCC9C7;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #CCC9C7;
}

::-webkit-scrollbar-corner {
  background: #DDDBD9;
}

::-webkit-scrollbar-track-piece {
  background: transparent;
}

// Other

app-avatar {
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
}

.checkbox-list {
  column-width: 200px;
  column-fill: balance;
  column-gap: 10px;

  mat-checkbox {
    width: 200px;
    display: flex;

    label {
      width: 100%;

      .mat-checkbox-label {
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1 1 auto;
      }
    }
  }
}

button {
  .when-loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    visibility: hidden;
  }

  &.submit-loading {
    .unless-loading {
      visibility: hidden;
    }

    .when-loading {
      visibility: visible;
    }
  }
}

.popover-overlay {
  pointer-events: none !important;
}

a:not([href]) {
  cursor: default;
}

@for $i from 0 through 4 {
  .color-score-#{$i} {
    color: map-get($scoresMap, $i);
  }
}

@for $i from 0 through 4 {
  .bg-score-#{$i} {
    background-color: map-get($scoresMap, $i);
  }
}

@for $i from 0 through 4 {
  .checkbox-score-#{$i} {
    $color: map-get($scoresMap, $i);

    &:not(.mat-checkbox-disabled) {
      .mat-checkbox-frame {
        border-color: $color !important;
      }

      &.mat-checkbox-checked,
      &.mat-checkbox-indeterminate {
        .mat-checkbox-background {
          background-color: $color !important;
        }
      }

      &.mat-checkbox-checked,
      &:active {
        .mat-ripple-element {
          background-color: $color !important;
        }
      }
    }
  }
}

.rating-select-panel {
  margin-top: 24px;
}

// Virtual scroll rerender hack - https://stackoverflow.com/a/24095217/2346893

cdk-virtual-scroll-viewport {
  animation: normal cdkVirtualScrollStart;
}

@keyframes cdkVirtualScrollStart {
  from {
    outline-color: transparent;
  }

  to {
    outline-color: transparent;
  }
}

body:not(.pdf-body) {
  .pdf-only {
    display: none;
  }
}

.html2pdf__overlay {
  pointer-events: none;
}

// Only show the first of consecutive banners

app-shared-banner,
.app-banner {
  &:not(.app-banner-hidden) + {
    app-shared-banner,
    .app-banner {
      &:not(.app-banner-always-show) {
        display: none;
      }
    }
  }
}

.app-banner-hidden {
  display: none !important;
}

.absolute-centered {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner-white.mat-progress-spinner circle {
  stroke: #fff;
}

.badge {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;

  .badge-text {
    display: inline-block;
    background-color: $accentColor;
    border-radius: 20px;
    padding: 4px 8px;
    color: white;
    line-height: 16px;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.4px;
  }

  .badge-icon {
    margin: 0;
    width: 24px;
    height: 24px;
    font-size: 24px;
    color: #eb4e29;
  }
}

.small-badge {
  .badge-text {
    height: 18px;
    line-height: 18px;
    padding: 0px 8px;
  }
}

.upsell-section {
  text-align: center;
  justify-content: center;
  overflow-y: auto;
  margin: auto;

  .bottom-section {
    width: 612px;
    margin: auto;
  }

  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }

  .sub-title {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #585A6E;
  }

  .bottom-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 24px 0px;
    gap: 16px;
  }

  .table-image {
    width: 100%;
    height: auto;
  }
}

@include media("<xs") {

  .upsell-section {
    margin: auto 16px;
    overflow-x: hidden;
    overflow-y: auto;

    .bottom-section {
      width: unset;
      margin-top: 40px;
    }

    .bottom-buttons{
      flex-direction: column;
      margin-top: 24px;
    }

    .contact-us-button{
      order: 2;
    }
  }
}

.upsell-disable-pointer-events {
  pointer-events: none;
}

.disabled-for-upsell,
.mat-button-disabled.disabled-for-upsell,
.mat-button.mat-button-disabled.mat-button-disabled.disabled-for-upsell,
.mat-flat-button.mat-button-disabled.mat-button-disabled.disabled-for-upsell {
  color: unset;
  background-color: unset;
  cursor: not-allowed;
}

.has-floating-widget {
  #hubspot-messages-iframe-container {
    transition: all 0.3s ease;

    // Mobile styles (both portrait and landscape)
    @media (max-width: 915px) {
      margin-bottom: 42px !important;

      &.expanded {
        margin-bottom: 120px !important;
      }
    }

    @media (max-width: 915px) and (orientation: portrait) and (max-width: 667px) {
      margin-right: 0 !important;
    }

    // Desktop styles
    @media (min-width: 1024px) {
      margin-right: 380px !important;
    }
  }
}

#floating-widget-spacer.enabled {
  @media (max-width: 399px) {
    margin:10px;
    padding:10px;
  }
}

.confetti-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: #DDDBD9;
}
