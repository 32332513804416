@import "@angular/material/theming";
@import "include-media";
@import "./helpers.scss";

$breakpoints: (
  xxs: 480px,
  xs: 600px,
  sm: 960px,
  md: 1280px,
  lg: 1920px,
  xl: 2560px,
  xxl: 3840px,
  // Height values
  hxs: 640px,
  hsm: 720px,
  hmd: 960px,
  hlg: 1080px
);

$media-expressions: (
  "screen": "screen",
  "print": "print",
  "handheld": "handheld",
  "landscape": "(orientation: landscape)",
  "portrait": "(orientation: portrait)",
  "retina2x": "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)",
  "retina3x": "(-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi)",
  "handset": "(max-width: 599.99px) and (orientation: portrait), (max-width: 959.99px) and (orientation: landscape)",
  "desktop": "(min-width: 599.99px) and (orientation: portrait), (min-width: 959.99px) and (orientation: landscape)"
);

// Palettes generated using http://mcg.mbitson.com/
$app-primary-palette-map: (
  50: #e5f8ed,
  100: #beedd3,
  200: #93e1b6,
  300: #68d498,
  400: #47cb82,
  500: #04D464,
  600: #23bc64,
  700: #1db459,
  800: #17ac4f,
  900: #0e9f3d,
  A100: #ceffdc,
  A200: #9bffb7,
  A400: #68ff92,
  A700: #4eff80,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);
$app-secondary-palette-map: (
  50: #fdeae5,
  100: #f9cabf,
  200: #f5a794,
  300: #f18369,
  400: #ee6949,
  500: #eb4e29,
  600: #e94724,
  700: #e53d1f,
  800: #e23519,
  900: #dd250f,
  A100: #ffffff,
  A200: #ffdad6,
  A400: #ffaba3,
  A700: #ff938a,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);
$primary-palette: mat-palette($app-primary-palette-map, 500);
$accent-palette: mat-palette($app-secondary-palette-map, 500);
$theme: mat-light-theme($primary-palette, $accent-palette);
$primary: #04D464;
$accent: #f22745;
$warn: #f7b82f;

$computerTimeColor: #04D464;
$accentColor: #0066ff;
$manualTimeColor: #e9d423;
$mobileTimeColor: #0066FF;
$greenDarkColor: #006B31;
$greenHoverColor: #24b464;
$greenSnowMintColor: #54F69F;
$breakTimeColor: #B3AEAB;
$jungleBlackColor: #2c2e3c;
$neutralColor: #574841;
$unratedColor: #9A918D;
$offlineColor: #a4a7b2;
$leaveTimeColor: #CA00F3;

$neutralDarkGreyColor: #a4a7b2;
$greyLightHoverColor: #dcdde1;
$greyDarkPressedColor: #6d6f78;

$errorColor: #F22745;
$errorDarkColor: #ca4323;

$tableBorderColor: rgba(87, 72, 65, 0.102);
$skeletonColor: #F1EFED;
$sliderTrackColor: #ccccd0;
$inputHintColor: $unratedColor;
$ctaButtonBorderColor: #d1d3d4;
$ctaButtonHoverColor: #039b47;
$greyLightBorderColor: #8c8f99;
$greenForSuccessBanner: #ddf5e7;

$yellowLightActive: #fce3ac;
$yellowNeutral: #fad482;
$yellowDarkCinnamon: #865c00;

$cyanLight: #b3feff;
$cyanDark: #1d7791;

$magentaLight: #ffdaff;
$magentaDark: #b400b4;

$scorePrimary: #02AB50;

$scoresMap: (
  0: $unratedColor,
  1: $unratedColor,
  2: $errorColor,
  3: $neutralColor,
  4: $scorePrimary
);

$pdfBorderColor: #efefef;

$formFieldVerticalMargin: 24px;

$scrollBarSize: 8px;

$pendingApprovalBackground: repeating-linear-gradient(-45deg, #fef7e6, #fef7e6 13px, #fdf0d5 13px, #fdf0d5 22px);
$leavePendingApprovalBackground: repeating-linear-gradient(-45deg, #fff4ff, #fff4ff 13px, #fcebfc 13px, #fcebfc 22px);

$tdLogoBlueColor: #0066ff;

// new theme
$primaryBackgroundGrey: #f1efed;

$uarKeystrokeCheckboxBg: #04D464;
$uarMouseMovementCheckboxBg: #0066ff;
$uarMouseClickCheckboxBg: #e9d423;
$expandIconColor: #9a918d;
$tableHeaderColor: #574841;
$legendLabelColor: #574841;
$actionIconColor: #574841;
$timetubeLabelColor: #9a918d;
$primaryTextColor: #9a918d;
$secondaryTextColor: #574841;
$dangerColor: #f22745;
$emptyStateColor: $dangerColor;
$warningColor: $dangerColor;
$defaultTextColor: #000000;
