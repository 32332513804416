@import "./variables.scss";

$brands: (
  timedoctor: (
    name: "timedoctor",
    logo: "/assets/images/brand/timedoctor/logo.svg",
    primary: #02AB50,
    accent: #0066FF,
    header: #342B27,
    subheader: #574841,
    background: #f1efed,
  ),
  liberty: (
    name: "liberty",
    logo: "/assets/images/brand/liberty/logo.svg",
    primary: #00b3cb,
    accent: #00b3cb,
    header: #21386e,
    subheader: #3e5187,
    background: #eae8e5,
  ),
);

@function get-map-props($map, $props) {
  $res: ();

  @each $prop in $props {
    $res: append($res, map-get($map, $prop));
  }

  @return $res;
}

@function hostContext($selector, $hasHost) {
  @if $hasHost {
    @return ":host-context(#{$selector})";
  } @else {
    @return $selector;
  }
}

@mixin whitelabelInner($hasHost, $props...) {
  $tdmap: map-get($brands, timedoctor);
  $isEmpty: list-length($props) == 0;

  & {
    @if $isEmpty {
      @content ($tdmap);
    } @else {
      @content (get-map-props($tdmap, $props)...);
    }
  }

  @each $brand, $map in $brands {
    #{hostContext("body.#{$brand}", $hasHost)} & {
      @if $isEmpty {
        @content ($map);
      } @else {
        @content (get-map-props($map, $props)...);
      }
    }
  }
}

// Use in components
@mixin whitelabel($props...) {
  @include whitelabelInner(true, $props...) using ($res...) {
    @content ($res...);
  }
}

// Use in global styles and deep part of components (When using :host for example)
@mixin whitelabelGlobal($props...) {
  @include whitelabelInner(false, $props...) using ($res...) {
    @content ($res...);
  }
}
