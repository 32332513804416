// Virtual scroll

.cdk-virtual-scroll-spacer {
  // This style was added by default before Angular 14
  // But it was removed for some reason
  // We need to add it back to avoid the issue with virtual scroll
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;

  // Required for cdkFixedFooter to work properly
  width: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: column;
}

// Buttons
.mat-button,
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  font-weight: 500;
  letter-spacing: 0.64px;

  &.compact {
    padding: 7px;
    min-width: 64px;
    min-height: 28px;
    line-height: 14px;
    font-size: 12px;
  }

  &.checked {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &.cancel-button {
    color: #a4a7b2;
  }

  mat-spinner {
    display: inline-block;
  }

  &[disabled] {
    .mat-spinner {
      circle {
        stroke: white;
      }
    }
  }

  &.badge-button {
    text-transform: none;
    background-color: #FCED6D;
    color: #6C6000;
    border-radius: 12px;
    padding: 3px 8px;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 16px;

    min-width: auto;
    min-height: auto;

    mat-icon {
      color: #6C6000;
    }

    &.mat-primary {
      background-color: #54F69F;
      color: #006B31 !important;


      mat-icon {
        color: #006B31;
      }
    }

    mat-icon {
      margin-right: 8px;
      width: 18px;
      height: 18px;
      line-height: 18px;
      font-size: 18px;
    }
  }
}

.mat-stroked-button {
  color: #9A918D;
}

.mat-icon-button,
.mat-mini-fab {
  &.compact {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    line-height: 32px;
    padding: 0;

    .mat-button-wrapper {
      padding: 0;
    }
  }
}

// Toggles
.mat-checkbox {
  position: relative;

  .mat-checkbox-label {
    flex-grow: 1;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: 0.25px;
    color: #000000;
  }

  .mat-checkbox-inner-container {
    width: 18px;
    height: 18px;

    .mat-checkbox-frame {
      border-color: #9A918D;
      border-radius: 3px;
    }
  }

  &[disabled],
  &.mat-checkbox-disabled {
    .mat-checkbox-inner-container {
      .mat-checkbox-frame {
        border-color: #CCCAC8;
      }
    }
    .mat-checkbox-label {
      color: #9A918D;
    }
  }
}

mat-pseudo-checkbox.mat-pseudo-checkbox {
  color: #9A918D;
  width: 18px;
  height: 18px;

  &:after {
    top: 4px;
    left: 2px;
  }
}

.mat-radio-button {
  .mat-radio-outer-circle {
    border-color: #9A918D;
  }

  label.mat-radio-label {
    font-size: 14px;
    letter-spacing: 0.25px;

    .mat-radio-label-content {
      flex-grow: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-left: 6px;
    }

    small {
      color: #9A918D;
      font-size: 14px;
      letter-spacing: 0.25px;
    }

    &:hover {
      .mat-radio-container .mat-radio-persistent-ripple {
        opacity: 0.04;
      }
    }
  }
}

mat-slide-toggle {
  .mat-slide-toggle-bar {
    background-color: #ccccd0;

    .mat-slide-toggle-thumb {
      background: white;
      box-shadow:
        0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 2px 1px -1px rgba(0, 0, 0, 0.12),
        0 1px 3px 0 rgba(0, 0, 0, 0.2);
    }
  }

  &.mat-slide-toggle.mat-checked.mat-primary {
    .mat-slide-toggle-bar {
      background-color: rgba(39, 194, 108, 0.3);
    }
  }

  &.multiline {
    white-space: normal;
    height: auto;

    label.mat-slide-toggle-label {
      align-items: flex-start;

      .mat-slide-toggle-content {
        white-space: normal;
      }

      .mat-slide-toggle-bar {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }
}

// Overlays
.mat-tooltip {
  word-break: break-word;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  background-color: #30302F;
  white-space: pre-line;
  max-width: 450px !important;
  box-sizing: border-box;
  text-align: left;
  padding: 6px 8px;

  &:empty {
    display: none;
  }

  &.mat-tooltip-handset {
    margin: 14px;
  }
}

a.mat-tooltip-trigger {
  text-decoration: underline dotted;
}

.mat-simple-snackbar {
  span {
    letter-spacing: 0.25px;
  }

  a {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
  }

  .mat-simple-snackbar-action {
    color: #beecd2;

    button {
      text-transform: uppercase;
    }
  }
}

.mat-menu-content {
  mat-checkbox {
    min-width: 176px;
    display: block;

    &:hover {
      background: #F1EFED;
    }

    label {
      padding: 8px 16px;
      display: flex;

      .mat-checkbox-inner-container {
        margin-left: 0;
      }
    }
  }
}

mat-dialog-actions.mat-dialog-actions {
  padding: 0;
  min-height: initial;
  margin-bottom: 0;
  margin-top: 18px;

  .mat-button-base + .mat-button-base:not(:first-child) {
    margin-left: 16px;
  }
}

@media screen and (max-width: 600px),
screen and (max-height: 600px) {
  .cdk-overlay-pane {
    max-width: none !important;
    overflow: auto;
  }

  .mat-dialog-content {
    max-height: none !important;
    flex: auto;
  }
}

// Tables
.mat-table {
  .mat-header-cell {
    position: relative;
    background: white;
    color: #574841;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.4px;

    &.mat-sort-header {
      cursor: pointer;
    }
  }

  .mat-cell {
    color: #000000;
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 20px;
  }

  .mat-footer-cell {
    background: white;
  }

  mat-header-cell.mat-table-sticky,
  mat-cell.mat-table-sticky {
    position: sticky !important;
  }
}

.mat-sort-header-container {
  width: 100%;

  .mat-sort-header-button {
    flex-grow: 1;
    text-align: left;
  }

  .mat-sort-header-arrow {
    color: #585a6e;
  }
}

// Inputs
mat-form-field {
  .mat-form-field-suffix {
    user-select: none;
  }

  &.mat-form-field-appearance-fill {
    .mat-form-field-flex {
      background-color: #F1EFED;
      padding: 0.5em 1em 0 1em;
    }
    .mat-select-arrow-wrapper {
      transform: translateY(-35%);
    }
  }

  .mat-form-field-label {
    color: #574841;
  }

  .mat-select-arrow {
    color: #574841;
  }

  &.mat-form-field-should-float {
    .mat-form-field-label {
      color: #9A918D;
    }
  }
}

mat-hint {
  a {
    text-decoration: underline;
    color: inherit;

    &[href] {
      cursor: pointer;
    }
  }

  &.error {
    color: $errorColor;
  }
}

mat-chip {
  user-select: none;

  &.mat-chip.mat-standard-chip::after {
    background: transparent;
  }

  &.mat-chip-selected {
    .mat-chip-remove {
      opacity: 1 !important;
    }
  }
}

// Select

mat-select {
  &.inline {
    width: auto;

    .mat-select-value,
    .mat-select-value-text {
      width: auto;
      display: inline;
    }

    .mat-select-arrow-wrapper {
      display: none;
    }
  }

  &.header {
    width: auto;

    .mat-select-value,
    .mat-select-value-text {
      width: auto;
      display: inline;
    }
  }
}

.mat-option {
  &.mat-option-flex {
    .mat-option-text {
      display: inline-flex;
    }
  }
}

.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
  background: #F1EFED;
}

.mat-select-panel {
  .mat-option.mat-selected:not(.mat-option-multiple) {
    background: #DDDBD9;
  }
}

.mat-primary {
  .mat-option.mat-selected:not(.mat-option-disabled) {
    color: initial;
  }
}

// Tabs
mat-tab-header {
  .mat-tab-label {
    color: #574841;
    opacity: 1;

    &.mat-tab-label-active {
      color: #04D464;
    }
  }
}

// Hide tabs without breaking indexes
@for $ind from 1 to 6 {
  .mat-tab-group.tab#{$ind}-hidden {
    div.mat-tab-label:nth-child(#{$ind}) {
      width: 0 !important;
      min-width: 0 !important;
      max-width: 0 !important;
      padding: 0 !important;
      margin: 0 !important;
      border-width: 0 !important;
      overflow: hidden !important;
    }
  }
}

// Snackbars
.mat-snack-bar-container {
  min-width: 348px !important;
  max-width: 720px !important;
  background: #000000;
  color: #fff;

  &.long-action {
    .mat-simple-snackbar {
      flex-direction: column;

      .mat-simple-snackbar-action {
        margin-top: 16px;
        align-self: flex-end;
      }
    }
  }
}

@media screen and (max-width: 788px) {
  .mat-snack-bar-container {
    max-width: 452px !important;
  }
}

@media screen and (max-width: 600px) {
  .mat-snack-bar-container {
    max-width: 388px !important;
  }
}

.mat-header-cell .mat-sort-header-container.mat-sort-header-sorted .mat-sort-header-arrow {
  opacity: 1 !important;
}

/* FORM FIELD VERTICAL SPACING */

.mat-form-field {
  margin-bottom: $formFieldVerticalMargin;

  &:last-child {
    margin-bottom: 0;
  }

  &:not(.mat-form-field-has-hint) {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      bottom: 0;
    }
    &.mat-form-field-disabled{
      .mat-form-field-underline::before {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
}

app-user-select-dropdown,
app-user-select-radio {
  &:last-child {
    .mat-form-field {
      margin-bottom: 0;
    }
  }
}

// Icons

mat-icon {
  user-select: none;
}

@for $i from 1 through 6 {
  .mat-icon-#{$i*2+12} {
    @include iconSize(#{$i*2 + 12}px);
  }
}

.mat-form-field-disabled {
  &:has(.mat-select) {
    .mat-form-field-wrapper {
      & > *:not(.mat-form-field-subscript-wrapper) {
        pointer-events: none;
      }
    }

    .mat-select {
      .mat-select-arrow-wrapper {
        display: none;
      }
    }
  }
}
